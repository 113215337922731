type RoleType = {
  machineName: string;
  displayName: string;
  description: string;
};

// NOTE: The order of these objects matters!
//  Roles should be sorted by privilege
//  The most privileged role(s) should come first
const rolesData: RoleType[] = [
  {
    machineName: 'superAdmin',
    displayName: 'Administrator',
    description: 'Superadmin can create tenants and assign users to them',
  },
  {
    machineName: 'admin',
    displayName: 'Organisatie projectbeheerder',
    description: 'Admin has access to the admin environment',
  },
  {
    machineName: 'viewAdmin',
    displayName: 'Inzicht beheerder',
    description: 'Admin that only has view access',
  },
  {
    machineName: 'adminNoContract',
    displayName: 'Organisatie projectbeheerder (geen salaris)',
    description: 'Same as admin except no salary permissions',
  },
  {
    machineName: 'user',
    displayName: 'Organisatie projectmedewerker',
    description: 'Normal user',
  },
];

// The order of roles. From most privileged, to least privileged
// Currently this is just based on the order objects appear in the `rolesData` array
export const roleNameOrder = rolesData.map((role) => role.machineName);

export default rolesData;
